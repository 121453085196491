import React, {useEffect} from 'react'
import LoginComponent from './login.js'
import { useHistory } from "react-router-dom";


function LoginPage (props) { 
    let history = useHistory();

    useEffect(() => { 
        if (props.user.signedIn === true) { 
            history.push("/app")
        }
    }, [props.user])
    // function handleClick() {
    //   history.push("/home");
    // }
    
    return(
        <div style={{display: 'flex', height: '100vh', backgroundColor: '#282C34'}}>
            <LoginComponent />
        </div>
    )
}

export default LoginPage