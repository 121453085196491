import React, {useContext, useState, useEffect} from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import './App.css';
import {auth, db} from './utils/firebase.js'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect, 
} from "react-router-dom";

import Landing from './routes/landing.js'
import LoginPage from './routes/LoginPage'
import App from './routes/app'

const theme = createMuiTheme({
  palette: {
    primary: { 500: '#7289DA' },
    type: "dark"
  },
})


const userDefault = { 
  signedIn: false, 
  profile: {}
}
export const UserContext = React.createContext(userDefault);

function PrivateRoute({ children, ...rest }) {
  const val = useContext(UserContext)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        val.signedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

async function getUserData(uid, profile = {}) { 
  let docRef = db.collection("users").doc(uid)
  let userData = await docRef.get().then(function(doc) {
      if (doc.exists) {
          console.log("Document data:", doc.data());
          return doc.data()
      } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          return false
    }
    }).catch(function(error) {
        console.log("Error getting document:", error);
    });

    if (userData === false) { 
      let userProfile = {
        firstName: '', 
        lastName: '', 
        displayName: profile.email, 
        photoUrl: '', 
        email: profile.email, 
      }
      await docRef.set(userProfile)
      .then(function() {
          console.log("Document successfully written!");
          let userData = userProfile
          return userData
      })
      .catch(function(error) {
          console.error("Error writing document: ", error);
      });
    }
    return userData

}


function MainApp() {
  const [contextVal, setcontextVal] = useState(userDefault);
  
  useEffect(() => {
    console.log("Getting User..")

    auth.onAuthStateChanged( async (user) => {
      if (user) {
        // User is signed in.
        console.log("User Sign In", user)
        let userData = await getUserData(user.uid, user)
        console.log("UserData", userData)


        let newUser = {...contextVal}
        newUser = { 
          signedIn: true, 
          profile : { 
            uid: user.uid, 
            email: user.email
          },
        }
        setcontextVal(newUser)

      } else {
        console.log("User not Signed in.")
        // No user is signed in.
        setcontextVal(userDefault)
      }
    });
  }, []);
  
  return (
    <UserContext.Provider value={contextVal}>
    <div className="App">
      <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
      <Router>
      <Switch>
          <PrivateRoute path="/app"> <App /> </PrivateRoute>
          <Route path="/login"> <LoginPage user={contextVal}/> </Route>
          <Route path="/"> <Landing /> </Route>
        </Switch>
      </Router>
      </SnackbarProvider>
    </ThemeProvider>
    </div>
    </UserContext.Provider>
  );
}

export default MainApp;
