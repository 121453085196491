import React from 'react'

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CollectionTabs from './CollectionTabs'

const useStyles = makeStyles((theme) => ({
    header: {
        height: '90px',
        backgroundColor: '#2F3136', 
        paddingLeft: 10, 
        textAlign: 'left'
    },
}));

export default function SingleCollection (props) { 
    const classes = useStyles();

    return( 
    <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
            <div className={classes.header}>  
                <h1>Users</h1>
            </div>
            <div style={{ flex: 1 }}>
                <CollectionTabs />
            </div>
    </div>)
}