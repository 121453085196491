  
import React, {useState} from 'react';
import {auth, db} from '../../utils/firebase'
import { useSnackbar } from 'notistack';

// MaterialUI Imports
import Snackbar from '@material-ui/core/Snackbar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#40444B', 
    padding: 20, 
    borderRadius: 5
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    color: 'white'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LoginComponent() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const signIn = () => { 
    auth.signInWithEmailAndPassword(email, password).catch(function(error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("Error signing in:", errorMessage)
      
      enqueueSnackbar(`Uh oh: ${errorMessage}`);
    });
  }

  const signUp = async () => { 
    console.log("Signing Up")
    await auth.createUserWithEmailAndPassword(email, password).catch(function(error) {
      // Handle Errors here.
      console.log("Could not create account", error.message)
      var errorCode = error.code;
      var errorMessage = error.message;
      enqueueSnackbar(`Uh oh: ${errorMessage}`);
    });

    console.log("Done")
    console.log(auth.currentUser)
    

  }

  return (
    <Container component="main" maxWidth="xs" >
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={ (e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={ (e) => setPassword(e.target.value)}
          />
          <Button
            // type="submit"
            onClick={signIn}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Login
          </Button>
          <Grid container>
            {/* <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          */}
            <Grid item>
              <Link href="#" variant="body2" onClick={signUp}>
                {"Don't have an account? Sign Up"}
              </Link>
          
            </Grid> 
          </Grid>
        </form>
  
      </div>
     
    </Container>
  );
}