import React from 'react'

// DocsList
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


export default function RawView (props) { 
    return(
        <div style={{height: '100%', width: '100%', display: 'flex'}}>
            <div style={{flex: 1}}> 
                <DocsList />
            </div>
            <div style={{flex: 2}}> 
                <h1>hii</h1>
            </div>

        </div>
    )
}


function DocsList(props) {
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const handleListItemClick = (event, index) => {
      setSelectedIndex(index);
    };
    return (<div>
      <List component="nav" aria-label="secondary mailbox folder">
        <ListItem
          button
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)}
        >
          <ListItemText primary="nd92iko18wnk" />
          <ChevronRightIcon />
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === 2}
          onClick={(event) => handleListItemClick(event, 2)}
        >
          <ListItemText primary="lksnf8902jiknq" />
          <ChevronRightIcon />
        </ListItem>
        <ListItem
          button
          selected={selectedIndex === 3}
          onClick={(event) => handleListItemClick(event, 3)}
        >
          <ListItemText primary="soidfnj0289n" />
          <ChevronRightIcon />
        </ListItem>
      </List>

    </div>);
}
