import React from 'react';
import logo from '../logo.svg';
import '../App.css';

function Landing() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1 style={{letterSpacing: 10}}>
          Stratos
        </h1>
        <h6>The easiest database you'll ever use. Coming Soon.</h6>
      </header>
    </div>
  );
}

export default Landing;
