import firebase from 'firebase'

var firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "stratoshq.firebaseapp.com",
    databaseURL: "https://stratoshq.firebaseio.com",
    projectId: "stratoshq",
    storageBucket: "stratoshq.appspot.com",
    messagingSenderId: "127460967443",
    appId: "1:127460967443:web:b5f7586a7bfae2ed4c2be6"
  };

firebase.initializeApp(firebaseConfig)


export const auth = firebase.auth()
export const db = firebase.firestore();

