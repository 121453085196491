import React, {useContext} from 'react'
import {
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
  } from "react-router-dom";
// Material UI Imports
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {UserContext} from 'App.js'
import Navbar from './components/Navbar'
import CollectionsList from './components/CollectionsList'
import SingleCollection from './components/SingleCollection'

const useStyles = makeStyles((theme) => ({
   
}));

const DatabaseView = (props) => { 
    return(
    <React.Fragment>

    <Grid container item direction="column" container xs={3} style={{backgroundColor: '#202225', padding: 10}} >
        <h2> Collections </h2>
        <CollectionsList />
    </Grid>
    <Grid container item xs={9} >
        {/* <h1> Hello </h1> */}
        <SingleCollection />
    </Grid>
    </React.Fragment>
    )
}

export default function App () { 
    const val = useContext(UserContext)
    let { path, url } = useRouteMatch();
    const classes = useStyles();

    return(
        <div style={{height: '100vh', color: '#efefef'}}>
        <Grid
            container
            direction="row"
            justify="stretch"
            alignItems="stretch"
            style={{height: '100%'}}
        >
        <Grid container item direction="column" container xs={1} style={{backgroundColor: '#2D2B55', padding: 2, maxWidth: 70}} >
            <h2 onClick={()=>console.log(val.profile)}> S </h2>
            <Navbar />
        </Grid>

        <Grid container item xs={11}>
            <Switch>
            <Route exact path={path}>
            <h3 >Profile</h3>
            <Link to={`${url}/db`}>DB</Link>
            </Route>
            <Route path={`${path}/db`}>
            <DatabaseView />
            </Route>
            </Switch>
        </Grid>
        </Grid>
        </div>
    )
}

