import React, {useContext, useEffect} from 'react';
import {auth} from 'utils/firebase.js'
import { useHistory } from "react-router-dom";
import {UserContext} from 'App.js'

import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import List from '@material-ui/core/List';

export default function Navbar(props) {
    let context = useContext(UserContext)
    const logout = () => { 
        auth.signOut().then( () => {
            console.log("Sign out success")
          }).catch(function(error) {
              console.log("Could not sign out: ", error)
          });
    }

    return (
      <List component="nav"  >
        <IconButton onClick={logout} >
            <ExitToAppIcon />
        </IconButton> 
      </List>
  );
}

