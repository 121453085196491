import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import RawView from './RawView.js'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function CollectionTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Raw" />
          <Tab label="List"  />
          <Tab label="Cards" />
        </Tabs>
      </AppBar>

      <div style={{flex: 1, display: 'flex', height: '100%'}}>
          <RawView />
      </div>

      {/* <TabPanel value={value} index={0} style={{backgroundColor: 'blue', flexGrow: 1}}>
        Raw
      </TabPanel>
      <TabPanel value={value} index={1}>
        List View
      </TabPanel>
      <TabPanel value={value} index={2}>
        Cards
      </TabPanel> */}


    </div>
  );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        style={{height: '100%'}}
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }